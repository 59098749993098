@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --silver: #dadfe3;
  --light-grey: #848484;
  --dark-grey: #242424;
  --grey: #717676;
  --blue: #486dff;
  --light-blue: #78adfd;
  --red: #ad0000;
}

.App {
  text-align: center;
}

.App-logo {
  height: 9vmin;
  pointer-events: none;
  margin-top: 1vmin;
}

.title{
  font-size: calc(10px + 2vmin);
  font-weight: 500;
  text-align: left;
  margin-bottom: 3vh;
}

.subtitle{
  font-size: calc(10px + 1vmin);
  font-weight: 500;
  text-align: left;
}

.body-container {
  margin: 5vh 3vw
}

.blog-container {
  width: 80%;
  display: flex;
  margin: auto;
  flex-direction: column;
  padding-bottom: 10vh;
}

.blog-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin-top: 5vh;
  margin-bottom: 2vh;
  margin-right: 2vw;
}

.blog-wrapper {
  display: block;
  align-self: center;
  align-content: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

.button-container {
  width: 20vmin;
  align-self: self-end;
  margin-top: 2vh;
}

.iframe-responsive {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.App-header {
  background-color: var(--dark-grey);
  min-height: 15vh;
  max-height: 30vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: var(--blue);
  font-size: calc(10px + 0.5vmin);
}

.auth {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 10vh;
}

.auth-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  border: 2px solid var(--light-blue);
  padding: 5vmin 10vmin;
  width: calc(50px + 30vmin);
  text-align: left;
  border-radius: 5px;
}

.container2 {
  max-width: 90%;
  margin: auto;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  text-align: justify;
  text-justify: inter-word;
  font-family: 'Poppins', 'sans-serif';
  font-size: calc(10px + 1vmin);
  padding-bottom: 3vh;
  padding-top: 1vh;
}

.container3 {
  max-width: 90%;
  display: flex;
  flex-direction: row;
  gap: 1vw;
  font-family: 'Poppins', sans-serif;
  font-size: calc(10px + 1vmin);
  margin-bottom: 2vh;
}

.contact-container {
  flex: 2;
}

.service-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 20vh;
}

.image-list-container {
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
}

.form-item {
  padding-bottom: 10px;
  font-size: calc(10px + 0.8vmin);
  font-family: 'Poppins', sans-serif;
  width: 100%;
}

.auth-form input {
  padding: 2px;
  font-size: calc(10px + 0.5vmin);
  font-family: 'Poppins', sans-serif;
  border: none;
  border-bottom: 1px solid var(--light-grey); 
}

.auth-form .btn-submit{
  display: block;
  align-self: center;
  margin-top: 10px;
  font-size: calc(10px + 0.8vmin);
}

.contact-form {
  margin-left: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.btn-scroll{
  background: none;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: calc(10px + 1vmin);
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
}

.btn-submit{
  background: var(--blue);
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  width: "20%"; 
  height: "5vh";
  border-radius: 4px;
  padding: 0 10px;
  font-size: calc(10px + 1.5vmin);
  font-family: 'Poppins', sans-serif;
}

.btn-submit:hover {
  background: var(--light-blue);

}

.btn-outlined {
  background: white;
  border: var(--blue) 1px solid;
  color: var(--blue);
  text-decoration: none;
  cursor: pointer;
  width: 150px;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 10px;
  font-family: 'Poppins', sans-serif;
}

.btn-outlined:hover {
  background: var(--blue);
  color: white;
}

.up-btn {
  position: sticky;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 3vh;
  bottom: 3vh;
  margin-right: 2vw;
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.info-container {
  border: 1.5px solid var(--blue);
  background-color: rgb(255, 255, 255);
  border-radius: 3px;
  align-self: center;
  align-items: center;
  max-width: 100%;
  position: absolute;
  z-index: 2;
  top: 25%;
  animation-name: show;
  animation-duration: 0.5s;
}

.info-text {
  font-family: 'Poppins', sans-serif;
  font-size: calc(10px + 0.5vmin);
  text-align: left;
  padding: 10px;
}

.posts {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  width: 90%;
  gap: 5vh;
}

.posts .post {
  display: flex;
  flex-direction: row;
  gap: 1vw;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  text-align: justify;
  text-justify: inter-word;
  border: 1px solid var(--light-grey);
  padding: 3vh;
  width: 100%;
  border-radius: 3px;
}

.post:nth-child(2n+1){
  flex-direction: row-reverse;
}

.post .post-img{
  flex: 2;
  width: 25vw;
  height: 25vw;
  display: flex;
  align-items: center;
  object-fit: fill;
  position: relative;
  box-shadow: -0.1vmin 0.1vmin 2vmin var(--light-grey);
}

.post-img::before{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--silver);
  top: 2vmin;
  left: -2vmin;
}

.post .post-content {
  margin: 0 2vw;
  flex: 2;
}

.post-content .body{
  font-size: calc(10px + 0.8vmin);
  font-family: 'Poppins', sans-serif;
}

a.title{
  color: black;
  text-decoration: none;
  font-size: calc(10px + 1.5vmin);
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

.profile-info{
  text-align: justify;
  text-justify: inter-word;
  margin: 0;
  font-size: calc(10px + 0.8vmin);
}

.soft-body {
  color:var(--grey);
}

.container {
  max-width: 90%;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navbar {
  height: 12vh;
  background-color: var(--dark-grey);
  display: block;
  position: relative;
}

.footer {
  height: 10vh;
  background-color: var(--dark-grey);
  display: block;
  text-align: center;
  align-content: center;
  position: relative;
  color: white;
  padding-top: 2vh;
  font-size: calc(10px + 1vmin);
}

.menu-icon {
  display: none;
}

.nav-elements {
  text-align: center;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: calc(10px + 1vmin);
  font-weight: 400;
  color: white;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: var(--light-grey);
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--grey);
}

@media (max-width: 600px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 0px;
  }
}

@media (max-width: 700px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 10vh;
    background-color: var(--grey);
    width: 0;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
    z-index: 3;
  }

  .nav-elements.active {
    width: 50%;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}